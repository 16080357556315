import { EditorSDK } from '@wix/platform-editor-sdk';
import { CHAT_APP_DEF_ID } from '../constants';

export const getBaseUrl = async (sdk: EditorSDK) => {
  const data: any = await sdk.document.tpa.app.getDataByAppDefId(
    '',
    CHAT_APP_DEF_ID,
  );
  return data.appFields?.platform?.editorScriptUrl?.replace(/\/[^\/]*$/, ''); //matches everything comes after the last "/"
};
